
import {useParams} from "react-router-dom";
import Flow from "./components/Flow/Flow";
import Card from "./components/Card/Card";
import {isMobile} from 'react-device-detect';  

// @ts-ignore | Weird 
import mqtt from "mqtt/dist/mqtt";
import './assets/main.css';
import "./App.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { cardLink, cardView, connectorMQTT, userDatas } from "@app/hoc/cache";
import Editor from "./components/UserInfo/editor";
import StaticFlow from "./components/Flow/StaticFlow";
import { Header } from "./components/UserInfo/header";
import { isFullScreen, isLocal } from "./components/Exam/containers/GameFullScreen";


const App = () =>{

  
    // http://localhost:3006/flow/62beb9beb4a19a4c82d096ae

    let { type,id,index } = useParams();
    //console.log( "connect:"+type+"/"+id+"/"+index)
    let smode = 0; // 1:static
    let scut = -1;
    if( type === undefined && id === undefined && index === undefined){
      //type = 'flow';
      //id = '633a7aa5f42efd41782ab430';
      // for test 0502
      type = 'gen';
      id = '644e1dd36ea318031bbe14bb'; //'644e1dd36ea318031bbe14bb';
    }
    if( type === 'id' ){ // for shortCut
      scut = parseInt(id as string)
/*
      type = 'flow';
      if( id === '11')
        id = '6353d348ee4f8f03254e1152';
      else if(id === '12')  
        id = '635f74a4ee4f8f03254e117b';
      else if(id === '13')  
        id = '6370657828dc490324bfd22e';
      else{
        type = 'flow';
        //id = '633a7aa5f42efd41782ab430';  
      }          
      */
      smode = 1;
    }


    const initialize = () => {
      localStorage.setItem('type',type as string);
      const examIndex = localStorage.getItem('index') as string;

      let uidService = index;
      if( uidService === undefined )
        uidService = '';
      if( uidService !== '' ) //&& localStorage.getItem('index') !== '' )  
        localStorage.setItem('index', uidService as string);

      let resetUser =  false;
      if( examIndex !== '' && index === ''){
        resetUser = localStorage.getItem('fid') !== id ? true : false;
        if( resetUser )
          localStorage.setItem('fid',id as string);
      }

      userDatas({...userDatas(), scut: scut, cfid: (id as string), sfid:'642ffd5375ca7403156c8386'});


      //localStorage.clear();
      localStorage.setItem('exBdate', '2019-01-01');
      
      let exId = "";
      if( localStorage.getItem('exID') !== null && !resetUser)
        exId = localStorage.getItem('exID') as string;
      let userName = "";
      if( localStorage.getItem('exName') !== null && !resetUser)
        userName = localStorage.getItem('exName') as string;

      userDatas({...userDatas(), exid: exId, nickname: userName});

      const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)
  
      var qos = 2;
      
      const options = {
        keepalive: 60,
        protocolId: 'MQTT',
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        will: {
          topic: 'WillMsg',
          payload: 'Connection Closed abnormally..!',
          qos: 2,
          retain: true
        },
        rejectUnauthorized: false
      }
      
      //console.log('connecting mqtt client')
      //- 1 ---------------------------------------------------------------------------
      //const mqtthost = 'ws://52.78.220.44:9001'; //wss://mqtt.liflo.io:443/liflo'
      const mqtthost = 'wss://mqtt.liflo.io:443/liflo';
      // @ts-ignore | Weird  
      const client = mqtt.connect(mqtthost, options)
      
      
      client.on('error', (err:any) => {
        //console.log('Connection error: ', err)
        client.end()
      })
      
      client.on('reconnect', () => {
        //console.log('Reconnecting...')
      })
      
      client.on('connect', () => {
        //console.log('Client connected:' + clientId)
        client.subscribe('/mbisq/cmd', { qos: 2 })
        client.subscribe('/mbisq_card/#', { qos: 2 })
      
        client.publish('/mbisq_client', 'ws connection ...!', { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");})
      })
  
      client.on('message', function (topic:any, message:any) {
        // message is Buffer
        //console.log( "--mqtt--" + topic + ":"+ message.toString());
        //client.end()
      })
      connectorMQTT().client = client;

      //- 2 ---------------------------------------------------------------------------
      
      const mqtthost2 = 'wss://mq1.liflo.io'; //wss://mqtt.liflo.io:443/liflo'
      //const mqtthost2 = 'wss://ec2-52-78-220-44.ap-northeast-2.compute.amazonaws.com:443/';
      //const mqtthost2 = 'ws://52.78.220.44:9001'; //wss://mqtt.liflo.io:443/liflo'
      //const mqtthost = 'wss://mqtt.liflo.io:443/liflo';
      // @ts-ignore | Weird  
      const client2 = mqtt.connect(mqtthost2, options)
      
      
      client2.on('error', (err:any) => {
        //console.log('Connection error: ', err)
        client2.end()
      })
      
      client2.on('reconnect', () => {
        //console.log('Reconnecting...')
      })
      
      client2.on('connect', () => {
        //console.log('Client connected:' + clientId)
        client2.subscribe('/mbisq/cmd', { qos: 2 })
        client2.subscribe('/mbisq_card/#', { qos: 2 })
      
        client2.publish('/mbisq_client', 'ws connection ...!', { qos: 2, retain: true },
        (err:any) => { if(err) alert("서버와 연결이 끊어졌습니다. 다시 시작하세요.");})
      })
  
      client2.on('message', function (topic:any, message:any) {
        // message is Buffer
        //console.log( "--mqtt--" + topic + ":"+ message.toString());
        //client.end()
      })
      connectorMQTT().client2 = client2;
      
    }

    initialize();
    
    useEffect(()=>{

    },[])

    useLayoutEffect(()=>{
      cardLink({ ...cardLink(),dev:window.location.host.indexOf('localhost') !== -1});
      //console.log('dev:'+window.location.host+'/'+cardLink().dev);

    },[]);
    //-------------
      //console.log("WINDOW 2(wxh): "+window.innerWidth+" x "+window.innerHeight);
      let hoffset = 0;
      let voffset = 0;
  
      //let isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
      if( isMobile !== true ){
        hoffset = 0;
        voffset = 0;
      }
      else{
        //console.log("mobile device");
        hoffset = 0;
        voffset = 0;
      }
      //after 221014----------------------------------------
      let mode = 1; // width 기준
      let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 / 0.64

      if(window.innerWidth > window.innerHeight)
        ratio = (window.innerHeight / window.innerWidth)-0.05;

      let cWidth = window.innerWidth - window.innerWidth/10;
      let cHeight = (window.innerWidth - hoffset)*ratio+voffset; //+100; fullscreen compenation

      if( localStorage.getItem('type') !== 'exam' ){ // height 기준
        cHeight = window.innerHeight - 70;
        cWidth = (cHeight - voffset)/ratio + hoffset - 60;
        if( cWidth+30 > window.innerWidth) cWidth = window.innerWidth - 60;
        mode = 2;
      }
      /*      
      if( type !== 'exam' ){ // height 기준
        cHeight = window.innerHeight - 70;
        cWidth = (cHeight - voffset)/ratio + hoffset;
        mode = 2;
      }
      
      if( cHeight > window.innerHeight){ // height 기준
        cHeight = window.innerHeight - 100;
        cWidth = (cHeight - voffset)/ratio + hoffset;
        mode = 2;
      }
      */
      /* before 221014
      let mode = 1; // width 기준
      let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 
      if(window.innerWidth > window.innerHeight)
        ratio = 0.7;
      let cWidth = window.innerWidth - 20;
      let cHeight = (window.innerWidth - hoffset)*ratio+voffset;
      if( cHeight > window.innerHeight){ // height 기준
        cHeight = window.innerHeight - 100;
        cWidth = (cHeight - voffset)/ratio + hoffset;
        mode = 2;
      }
      */
      //console.log("CARD "+(mode==1?'width':'height')+"(wxh): "+cWidth+" x "+cHeight+" new");
      cHeight = (cHeight - 0)|0;
      cWidth = (cWidth + 0)|0;
      /*
      if( isMobile !== true ){
        hoffset = 160;
        voffset = 96;
      }
      else{
        //console.log("mobile device");
        hoffset = -10;
        voffset = -96;
      }
      
      let mode = 1; // width 기준
      let ratio = 2.6; //1.618; golden ratio
      let cWidth = window.innerWidth;
      let cHeight = (window.innerWidth - hoffset)*ratio+voffset;
      if( cHeight > window.innerHeight){ // height 기준
        cWidth = (window.innerHeight - voffset)/ratio + hoffset;
        cHeight = window.innerHeight;
        mode = 2;
      }
  
      //console.log("CARD "+(mode==1?'width':'height')+"(wxh): "+cWidth+" x "+cHeight+" new");
      cHeight = (cHeight - 100)|0;
      cWidth = (cWidth+10)|0;
      */
      ratio = (cHeight*100 / cWidth)|0;
      userDatas({...userDatas(), width:cWidth, height:cHeight, ratio: ratio});
      // eslint-disable-next-line no-restricted-globals 
      //console.log(location.origin);
      cardView({ mobile:isMobile, width:cWidth, height:cHeight, src: type === 'id'?1:0});
      //----------------------------------------
  
    //<SpringLayout id={id} index={index}/>
    //document.domain = 'liflo.io';

    const [goCard, setGoCard] = useState('');
    const [nextID, setNextID] = useState('');
    useEffect(()=>{
      setNextID(goCard);
      setGoCard('');
    },[goCard])

  return (
    <div className="App">
      { !isFullScreen() && <Header curr={'plan'} onGoCard={(id:any)=>setGoCard(id)}/>}
      { isLocal() && <Editor />}
      { type !== 'card' && smode === 0 && <Flow flow={ {id:id, type: type, meta:[]}} goCard={nextID}/>}
      { type !== 'card' && smode === 1 && <StaticFlow flow={ {id:id, type: type, meta:[]}} goCard={nextID}/>}
      { type === 'card' && <Card card={ {id:id, type: type, meta:[]}}/>}
      
    </div>
  );
}
// <Copyright />
export default App;
